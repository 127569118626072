<template>
  <div>
    <v-row
      ><div class="display-1 pa-2 font-weight-light">
       Student History
      </div>
      </v-row
    >
    <br><br>
    <v-card  color="grey lighten-4 ">
    <v-row style="margin-left:10px"><v-col cols="12" sm="2"> {{"Gr Number:"+ grno}}</v-col>
    <v-col cols="12" sm="3"> {{"Name:"+ name}}</v-col>
    <v-col cols="12" sm="3"> {{"Email:"+ email}}</v-col>
    <v-col cols="12" sm="3"> {{"Mobile Number:"+mobile}}</v-col>
      </v-row>
      </v-card><br>
    <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="loading"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader>
    
    <v-card v-else-if="Array.isArray(tabledata) && tabledata.length" class="ma-3 pa-2">
      
     <v-row justify="end">
     <v-col cols="12" sm=3>

     
     <v-text-field
     style="padding:0px;margin:0px;"
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
          ></v-text-field>
     </v-col>
    <!--- <download-excel
              class="mx-5"
              :data="headers"
              :fields="tabledata"
              worksheet="Company_List"
              name="Company_List.xls"
            >
              <v-btn color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>-->
            <v-btn  @click="exportexcel()" color="success" dark class="mx-5">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
     </v-row>
      <div style="width: 100%; overflow: auto">
     
     <v-data-table  id="exceltable"
      :headers="headers"
      :items="tabledata"
      class="elevation-1"
      :footer-props="{'items-per-page-options':[50, 100, -1]}"
      :search="search"
    >n

  <template v-slot:item.company="{ item }">
  <v-simple-table>
                                        <thead>
                                            <tr>
                                               
                                                <th class="text-left">Round </th>
                                                <th class="text-left">Round Date </th>
                                                <th class="text-left">Status </th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                             
                                            <tr v-for="it in item.company" :key="it.id">                                               
                                                
                                                <td v-if="it.companyOfferingLearnerSelection=='PLACED'">
                                                  <span style="color: green; font-weight: bold ">{{ it.round }}</span>
                                                    
                                                </td>

                                                <td v-else>
                                                  <span >{{ it.round }}</span>
                                                    
                                                </td>

                                                <td v-if="it.companyOfferingLearnerSelection=='PLACED'">
                                                  <span  style="color: green; font-weight: bold"> {{ it.rounddate }}</span>
                                                   
                                                </td>

                                                <td v-else>
                                                  <span > {{ it.rounddate }}</span>
                                                   
                                                </td>
                                                 
                                                <td v-if="it.companyOfferingLearnerSelection=='PLACED'">
                                                   <span style="color: green; font-weight: bold">{{ it.status}}</span> 
                                                </td>
                                                <td v-else>
                                                   <span >{{ it.status}}</span> 
                                                </td>

                                               
                                                </tr>
                                        </tbody>
                                         </v-simple-table>
                                        </template>
  
     </v-data-table> 
      </div>
    </v-card>
 
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

  </div>
</template>
<script>
import axios from "axios";
import Language from "../AdminMaster/Language.vue";
import {table2excel} from "../jquery.table2excel";
export default {
  components: { Language },
  data() {
    return {
      selorg: [],
      selay: [],
      orglist: [],
      aylist: [],
      grlist:[],
      snackbar_msg: "",
     
      snackbar: false,
      color: "",
      data: [],
      tabledata: [],
      dataarray: [
       
      ],
      name:"",
      grno:"",
      email:"",
      mobile:"",
      loading: false,
      headers: [],
        headers: [
      { text: "Sr.No", value: "srno" }, 
       { text: "Company", value: "companyy[0]" },
     
     
     
      { text: "Round Details", value: "company" },
          
    
    ],
      search: "",
    };
  },
  mounted() {
    this.init();
    this.getplacementdashboardreport();
  },
  methods: {
      exportexcel() {
     
        $("#exceltable").table2excel({
    
    name: "Worksheet Name",
    filename: "IndividualStudentReport", //do not include extension
    fileext: ".xls" // file extension
  }); 
   
    },
    init() {
      axios.post("/PlacementReport/getorganizationlist").then((res) => {
        if (res.data.msg == "200") {
          this.orglist = res.data.orglist;
          this.aylist = res.data.aylist;
        }
      });
    },
    getplacementdashboardreport() {
     
    //   if (this.grno.length == 0) {
    //     this.showSnackbar("red", "Please Enter Gr Number");
    //   } else {
    //     this.data = [];
    //     this.loading = true;
    //     var params = { grlist: this.grno };
        axios
          .post("/PlacementReport/getstudenthistory")
          .then((res) => {
            this.loading = false;
            if (res.data.msg == "200")
             {
                //   this.data = res.data.data;
                  this.tabledata = res.data.learnerlist;
                  this.name=res.data.name;
                  this.email=res.data.email;
                   this.grno=res.data.grno;
                   this.mobile = res.data.mobile;
                
                //  console.log(this.tabledata)    
                    //  console.log(this.dataarray);
            }else if(res.data.msg == "402"){
                   this.showSnackbar("red", res.data.error);
            }
          });
      }
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  
};
</script>